<template>
    <div class="editor">
        <div
            ref="pageEdit"
            class="page-edit">
            <div
                v-if="page.preconfigured"
                class="layout preconfigured">
                <div class="position position-1">
                    <p class="messaging">
                        This page was selected from a list of pre-configured pages.
                    </p>
                    <p class="page-title">
                        {{ page.title }}
                    </p>
                </div>
            </div>
            <div
                v-else
                class="layout"
                :class="layoutClass">
                <div
                    v-for="(slot,i) in page.slots"
                    :key="i"
                    class="position"
                    :class="`position-${i+1}`"
                    @click="$emit('show-page-settings', i)">
                    <span class="position-number">{{ i + 1 }}</span>
                    <div class="widget-info">
                        <div class="widget">
                            <p
                                v-if="slot.component == 'Funnel'"
                                class="icon">
                                <!-- eslint-disable-next-line -->
                                <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(3 3)"><rect height="81" rx="7" stroke="#fff" stroke-width="5" width="81"/><g fill="#fff"><path d="m24 22h34l-11.4363636 16h-11.1272728z"/><path d="m35 43h11l-1.8333333 16h-7.3333334z"/></g></g></svg>
                            </p>
                            <p
                                v-else-if="slot.component == 'Billboard'"
                                class="icon">
                                <!-- eslint-disable-next-line -->
                                <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="#fff"><rect height="81" rx="7" stroke-width="5" width="81" x="3" y="3"/><path d="m54.5 31.5v23" stroke-linecap="square" stroke-width="2"/><path d="m32.5 31.5v23" stroke-linecap="square" stroke-width="2"/></g><path d="m16 38h12v10h-12z" fill="#fff"/><path d="m38 38h12v10h-12z" fill="#fff"/><path d="m60 38h12v10h-12z" fill="#fff"/></g></svg>
                            </p>
                            <p
                                v-else-if="slot.component == 'Graph'"
                                class="icon">
                                <!-- eslint-disable-next-line -->
                                <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect height="81" rx="7" stroke="#fff" stroke-width="5" width="81" x="3" y="3"/><g transform="translate(20 24)"><path d="m2.97903442 34.6735479 14.33333338-20.2917039 14.3333333 13.900489 14.3333333-27.41522381" stroke="#fff" stroke-width="3"/><g fill="#fff" stroke="#00a2ea" stroke-width="2"><circle cx="32" cy="29" r="4"/><circle cx="3" cy="34" r="4"/><circle cx="17" cy="15" r="4"/><circle cx="45" cy="3" r="4"/></g></g></g></svg>
                            </p>
                            <p
                                v-else-if="slot.component == 'Table'"
                                class="icon">
                                <!-- eslint-disable-next-line -->
                                <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect height="81" rx="7" stroke="#fff" stroke-width="5" width="81" x="3" y="3"/><path d="m19 53h9v7h-9z" fill="#fff"/><path d="m33.5 56.5h8" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m50.055556 56.5h16.888888" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m19 39h9v7h-9z" fill="#fff"/><path d="m33.5 42.5h8" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m50.055556 42.5h16.888888" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m19 25h9v7h-9z" fill="#fff"/><g stroke="#fff" stroke-linecap="square" stroke-width="3"><path d="m33.5 28.5h8"/><path d="m50.055556 28.5h16.888888"/></g></g></svg>
                            </p>
                            <p class="name">
                                {{ slot.component || 'No Widget Selected' }}
                            </p>
                        </div>
                        <p
                            v-if="slot.component && (!slot.metrics || slot.metrics.length == 0)"
                            class="no-metrics">
                            No Metrics Selected
                        </p>
                        <ul class="metrics">
                            <li
                                v-for="(metric,i) in slot.metrics"
                                :key="i"
                                class="metric">
                                <p class="label">
                                    Metric {{ i+1 }}:
                                </p>
                                <p class="name">
                                    {{ metric }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'PageEditor',
    props: {
        page: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            boundingRect: {},
            currentScrollPos: 0,
        };
    },
    computed: {
        isVisible() {
            const tolerance = 250;
            return (this.scroll >= this.boundingRect.top + this.currentScrollPos - tolerance )
                && (this.scroll <= this.boundingRect.bottom + this.currentScrollPos - tolerance);
        },
        layoutClass() {
            return this.page.meta.layout.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        }
    },
    mounted() {
        this.currentScrollPos = window.pageYOffset;
        this.boundingRect = this.$refs.pageEdit.getBoundingClientRect();
    },
    methods: {
        ...mapActions([
            'updateWidget',
        ]),
        updateComponent(slotIndex, { component, dataType }) {
            this.updateWidget({ slotIndex, component, dataType });
        }
    }
};
</script>

<style lang="scss" scoped>
.page-edit {
    padding: 60px;
    width: 1100px;
    height: 850px;
    background: #F7F8FC;
    border: 1px solid #292F31;
    .position {
        position: relative;
        background-color: $white;
        border: 1px solid #292F31;
        background-image: url("./assets/background-cog.svg");
        background-position: center;
        margin: 20px;
        cursor: pointer;
    }
    .position-number {
        font-size: 15px;
        line-height: 22px;
        color: $white;
        background: darken($blue-primary, 15%);
        display: inline-block;
        margin: 15px;
        height: 24px;
        width: 24px;
        text-align: center;
        border-radius: 50%;
    }
    .layout {
        display: flex;
        flex-wrap: wrap;
        height: 100%
    }
    .full,
    .preconfigured {
        flex-direction: column;
        .position-1 {
            flex: 1;
        }
    }
    .preconfigured {
        text-align: center;
        .position {
            padding: 60px;
        }
        .messaging {
            font-size: 16px;
            font-weight: 600;
            color: darken($blue-primary, 15%);
        }
        .page-title {
            font-size: 24px;
            font-weight: 600;
        }
    }
    .widget-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .widget {
            display: flex;
            align-items: center;
            .icon {
                margin: 0;
                height: 40px;
                width: 40px;
                svg {
                    height: 100%;
                    width: 100%;
                }
                rect {
                    fill: #00a2ea;
                }
            }
            .name {
                font-weight: 600;
                margin-left: 15px;
                color: $black;
            }
        }
        .no-metrics {
            margin: 10px;
        }
        .metrics {
            list-style: none;
            .metric {
                display: flex;
                margin: 10px;
            }
            .label {
                color: $gray;
                margin: 0;
            }
            .name {
                color: $black;
                margin-left: 5px;
            }
        }
    }
    .two-one {
        flex-direction: row;
        .position-1,
        .position-2 {
            flex: 1;
        }
        .position-3 {
            width: 100%;
        }
    }
    .one-two {
        flex-direction: row;
        .position-1 {
            width: 100%;
        }
        .position-2,
        .position-3 {
            flex: 1;
        }
    }
    .two-up {
        flex-direction: column;
        .position-1,
        .position-2 {
            flex: 1;
        }
    }
    .four-up {
        flex-direction: row;
        flex-wrap: wrap;
        .position {
            width: calc(50% - 40px);
        }
    }
}
</style>